import { Stack, Typography } from "@mui/material"
import moment from "moment"
import { Colors } from "../utils/utilities"
import Countdown from 'react-countdown';
import { motion, MotionConfig } from "motion/react"

const RenderCountDown = ({ days, hours, minutes, seconds }: any) => {
    return (
        <div style={{ width: '100%' }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={2}
            >
                <MotionConfig transition={{ duration: 0.8, delay: 5.5 }}>
                    <motion.span initial={{ opacity: 0, y: -30 }} animate={{ opacity: 1, y: 0 }}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <Typography variant="h5" color={Colors.MAIN} sx={{ padding: 1, borderRadius: 3, backgroundColor: Colors.SUBMAIN, aspectRatio: 1, textAlign: 'center' }}>
                                {days < 10 ? '0' + days.toString() : days}
                            </Typography>
                            <Typography variant="caption" fontWeight={'300'} color="#fff">DAYS</Typography>
                        </Stack>
                    </motion.span>
                </MotionConfig>
                <MotionConfig transition={{ duration: 0.8, delay: 5.8 }}>
                    <motion.span initial={{ opacity: 0, y: -30 }} animate={{ opacity: 1, y: 0 }}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <Typography variant="h5" color={Colors.MAIN} sx={{ padding: 1, borderRadius: 3, backgroundColor: Colors.SUBMAIN, aspectRatio: 1, textAlign: 'center' }}>
                                {hours < 10 ? '0' + hours.toString() : hours}
                            </Typography>
                            <Typography variant="caption" fontWeight={'300'} color="#fff">HOURS</Typography>
                        </Stack>
                    </motion.span>
                </MotionConfig>
                <MotionConfig transition={{ duration: 0.8, delay: 6 }}>
                    <motion.span initial={{ opacity: 0, y: -30 }} animate={{ opacity: 1, y: 0 }}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <Typography variant="h5" color={Colors.MAIN} sx={{ padding: 1, borderRadius: 3, backgroundColor: Colors.SUBMAIN, aspectRatio: 1, textAlign: 'center' }}>
                                {minutes < 10 ? '0' + minutes.toString() : minutes}
                            </Typography>
                            <Typography variant="caption" fontWeight={'300'} color="#fff">MINS</Typography>
                        </Stack>
                    </motion.span>
                </MotionConfig>
            </Stack>
        </div>
    )
}

export const CountdownEvent = () => {
    return (
        <Countdown
            date={moment('2024-12-25').format('YYYY-MM-DD')}
            renderer={RenderCountDown}
            zeroPadDays={2}
            zeroPadTime={2}
        ></Countdown>
    )
}