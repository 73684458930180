import { Dialog, DialogContent, Icon, Slide, Stack, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { Colors } from "../utils/utilities";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SuccessAlert = (props: { isOpen: boolean }) => {
    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{ sx: { borderRadius: 5, maxWidth: '50vh', backgroundColor: Colors.SUBMAIN } }}
        >
            <DialogContent sx={{ backgroundColor: Colors.SUBMAIN, paddingY: 5 }}>
                <Stack direction={'column'} alignItems={'center'} spacing={3} justifyContent={'center'}>
                    <Icon sx={{ color: '#b6fe25', fontSize: 50 }}>check_circle</Icon>
                    <Typography variant="body1" textAlign={'center'} color="#fff">Your information has been successfully saved</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const ErrorAlert = (props: { isOpen: boolean }) => {
    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{ sx: { borderRadius: 5, maxWidth: '50vh', backgroundColor: Colors.SUBMAIN } }}
        >
            <DialogContent sx={{ backgroundColor: Colors.SUBMAIN, paddingY: 5 }}>
                <Stack direction={'column'} alignItems={'center'} spacing={3} justifyContent={'center'}>
                    <Icon sx={{ color: '#489dd9', fontSize: 50 }}>info</Icon>
                    <Typography variant="body1" textAlign={'center'} color="#fff">Your information has been saved</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}