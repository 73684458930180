import { AxiosMethods, hashValue } from "../utils/interceptors";

// export function HTTPGetAttendance(): Promise<any> {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await AxiosMethods().get(`attendance/get`, {
//         hash: hashValue,
//       });
//       return resolve(response);
//     } catch (error: any) {
//       return reject(error);
//     }
//   });
// }

export function HTTPSendAttendance(param: { name: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethods().post(`attandance/add`, {
        name: param.name,
        hash: hashValue,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetInvitation(param: { card_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethods().post(`attandance/get-invit`, {
        card_id: param.card_id,
        hash: hashValue,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
