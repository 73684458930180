import React from 'react';
import './App.css';
import { CENTER } from './utils/utilities';
import { Assets } from './assets';
import { InformationSection } from './components/informations';
import { useLocation } from 'react-router';

const App = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500)
  }, [pathname]);

  return (
    <div style={{ ...CENTER, width: '100%', backgroundColor: '#222223' }}>
      <div style={{
        width: '100%',
        maxWidth: '425px',
        minHeight: '100vh',
        backgroundColor: '#000',
        backgroundImage: `url(${Assets.image.background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        WebkitBackgroundSize: 'cover'
      }}>
        <InformationSection />
      </div>
    </div >
  );
}

export default App;
