import { CENTER, Colors } from "../utils/utilities";
import { Assets } from "../assets";
import { Icon, Link, Stack, Typography } from "@mui/material";
import { CountdownEvent } from "../components/countdown";
import moment from "moment";
import { motion, MotionConfig } from "motion/react";
import { useSearchParams } from "react-router";

const desc =
  "Discover the future where apparel meets blockchain magic! Join us for the big reveal of DRX Token, our cutting-edge digital currency.";

export const InformationSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      spacing={3}
      sx={{ paddingY: 5 }}
    >
      <MotionConfig transition={{ duration: 0.8 }}>
        <motion.img
          src={Assets.icon.logoText}
          style={{ width: "40%", height: "auto", objectFit: "contain" }}
          alt='logo'
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
        />
      </MotionConfig>
      <div style={{ width: "100%" }}>
        {searchParams.get("name") ? (
          <MotionConfig transition={{ duration: 0.8, delay: 0.5 }}>
            <motion.span
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <Typography
                textAlign={"center"}
                variant='h6'
                color='#fff'
                fontWeight={"400"}
              >
                Hello, Mr./Mrs./Ms.
                <br />
                {searchParams.get("name")}
              </Typography>
            </motion.span>
          </MotionConfig>
        ) : null}
        <MotionConfig transition={{ duration: 0.8, delay: 1 }}>
          <motion.span
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Typography
              textAlign={"center"}
              variant='body1'
              color='#ffffffBB'
              fontWeight={"300"}
            >
              YOU ARE CORDIALLY INVITED TO:
            </Typography>
          </motion.span>
        </MotionConfig>
      </div>
      <MotionConfig transition={{ duration: 1, delay: 1.5 }}>
        <motion.div
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          style={{
            width: "90%",
            backgroundColor: "#ffffff30",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            ...CENTER,
            padding: "20px 0",
            borderRadius: 20,
            border: "1px solid #fff",
          }}
        >
          <Stack direction={"column"} spacing={1} width={"90%"}>
            <MotionConfig transition={{ duration: 0.8, delay: 2 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography
                  variant='h6'
                  textAlign={"center"}
                  color={Colors.MAIN}
                >
                  DRX TOKEN EVENT
                </Typography>
              </motion.span>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 2.3 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography variant='body1' textAlign={"center"} color='#fff'>
                  GET TOGETHER WITH DRX TOKEN
                </Typography>
              </motion.span>
            </MotionConfig>
            <div />
            <MotionConfig transition={{ duration: 0.8, delay: 3 }}>
              <motion.p
                key={desc}
                variants={{
                  hidden: {},
                  visible: {
                    opacity: 1,
                    transition: { staggerChildren: 0.1, duration: 0.8 },
                  },
                }}
                initial='hidden'
                animate='visible'
                style={{
                  textAlign: "justify",
                  color: "#C7C7C7",
                  fontSize: "14px",
                }}
              >
                {desc.split("").map((char, i) => (
                  <motion.span
                    key={`${char}-${i}`}
                    variants={{
                      hidden: { opacity: 0 },
                      visible: {
                        opacity: 1,
                        transition: { opacity: { duration: 0.5 } },
                      },
                    }}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.p>
            </MotionConfig>
            <div />
            <MotionConfig transition={{ duration: 0.8, delay: 3.5 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography variant='body1' textAlign={"left"} color='#fff'>
                  Information Event:
                </Typography>
              </motion.span>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 4 }}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <div
                    style={{
                      backgroundColor: Colors.SUBMAIN,
                      borderRadius: 10,
                      ...CENTER,
                      aspectRatio: 1,
                      padding: 10,
                    }}
                  >
                    <Icon fontSize={"medium"} sx={{ color: Colors.MAIN }}>
                      calendar_today_outlined
                    </Icon>
                  </div>
                  <div>
                    <Typography
                      variant='subtitle2'
                      fontWeight={"300"}
                      color='#C7C7C7'
                    >
                      Date:
                    </Typography>
                    <Typography variant='body2' color='#fff'>
                      {moment("2024-12-25").format("dddd, DD MMM YYYY")}
                    </Typography>
                  </div>
                </Stack>
              </motion.div>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 4.5 }}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <div
                    style={{
                      backgroundColor: Colors.SUBMAIN,
                      borderRadius: 10,
                      ...CENTER,
                      aspectRatio: 1,
                      padding: 10,
                    }}
                  >
                    <Icon fontSize={"medium"} sx={{ color: Colors.MAIN }}>
                      place
                    </Icon>
                  </div>
                  <div>
                    <Typography
                      variant='subtitle2'
                      fontWeight={"300"}
                      color='#C7C7C7'
                    >
                      Location:
                    </Typography>
                    <Typography variant='body2' color='#fff'>
                      {"Robot & Co Pacific Place"}
                    </Typography>
                  </div>
                </Stack>
              </motion.div>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 5 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography
                  variant='body1'
                  textAlign={"left"}
                  color='#fff'
                  paddingTop={3}
                >
                  Countdown Event:
                </Typography>
              </motion.span>
            </MotionConfig>
            <CountdownEvent />
          </Stack>
        </motion.div>
      </MotionConfig>
      <br />
      <MotionConfig transition={{ duration: 0.8, delay: 6.5 }}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{ width: "90%", ...CENTER }}
        >
          <Link
            alignItems={"center"}
            width={"100%"}
            sx={{ textDecoration: "none" }}
            href='https://maps.app.goo.gl/TmWjPkgnAcGaUdrKA'
            target='_blank'
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"center"}
              sx={{
                border: `1px solid ${Colors.MAIN}`,
                borderRadius: 50,
                padding: "12px 16px",
                backgroundColor: "#ffffff30",
                cursor: "pointer",
              }}
            >
              <Typography variant='body1' color={Colors.MAIN}>
                See Location
              </Typography>
              <Icon sx={{ color: Colors.MAIN }} fontSize={"small"}>
                place
              </Icon>
            </Stack>
          </Link>
        </motion.div>
      </MotionConfig>
    </Stack>
  );
};
