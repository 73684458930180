import Axios from "axios";

export const hashValue =
  "NucR8n0pSptEWKKveymkJg==:tJjqwkFd99V84V0iYFEuBm9eaSAVhIUpQSArR6iq5uM=";

export function AxiosMethods(token?: string) {
  let instance = Axios.create({
    baseURL: "https://api-event.drxtoken.io/event/",
    timeout: 1000 * 1000,
    headers: {
      Authorization: token !== undefined ? `Bearer ${token}` : "-",
    },
  });

  instance.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
}
