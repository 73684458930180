import { CENTER, Colors } from "../utils/utilities";
import { Assets } from "../assets";
import { CircularProgress, Icon, Link, Stack, Typography } from "@mui/material";
import { CountdownEvent } from "../components/countdown";
import moment from "moment";
import { motion, MotionConfig } from "motion/react";
import { useSearchParams } from "react-router";
import { isIOS, isIOS13, isIPad13, isSafari } from "react-device-detect";
import { HTTPSendAttendance, HTTPGetInvitation } from "../servers/attendance";
import React from "react";
import { ErrorAlert, SuccessAlert } from "./alerts";

const desc =
  "Discover the future where apparel meets blockchain magic! Join us for the big reveal of DRX Token, our cutting-edge digital currency.";

export const InformationSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = React.useState(searchParams.get("name") as string)
  const [isLoading, setLoading] = React.useState(false)
  const [isSuccess, setSuccess] = React.useState(false)
  const [isError, setError] = React.useState(false)

  React.useEffect(() => {
    // onLanding()
  }, [])

  const onLanding = async () => {
    setLoading(true)
    if (searchParams.get("name") === "null") {
      try {
        let kartu = await HTTPGetInvitation({ card_id: searchParams.get("id") as string })
        setName(kartu.data[0].name)
      } catch (error) {
        setError(true)
        console.log(error)
      }
      setTimeout(() => {
        setSuccess(false)
        setError(false)
      }, 2000)
      setLoading(false)

    } else {
      setName(searchParams.get("name") as string)
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await HTTPSendAttendance({ name: name as string })
      setSuccess(true)
    } catch (error) {
      setError(true)
      console.log(error)
    }
    setTimeout(() => {
      setSuccess(false)
      setError(false)
    }, 2000)
    setLoading(false)
  }

  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      spacing={3}
      sx={{ paddingY: 5 }}
    >
      <MotionConfig transition={{ duration: 0.8 }}>
        <motion.img
          src={Assets.icon.logoText}
          style={{ width: "40%", height: "auto", objectFit: "contain" }}
          alt='logo'
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
        />
      </MotionConfig>
      <div style={{ width: "100%" }}>
        {/* {searchParams.get("name") ? (
          <MotionConfig transition={{ duration: 0.8, delay: 0.5 }}>
            <motion.span
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <Typography
                textAlign={"center"}
                variant='h6'
                color='#fff'
                fontWeight={"400"}
              >
                Hello, {name}
              </Typography>
            </motion.span>
          </MotionConfig>
        ) : null} */}
        <MotionConfig transition={{ duration: 0.8, delay: 1 }}>
          <motion.span
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Typography
              textAlign={"center"}
              variant='h6'
              color='#ffffffBB'
              fontWeight={"3400"}
            >
              YOU ARE CORDIALLY INVITED TO:
            </Typography>
          </motion.span>
        </MotionConfig>
      </div>
      <MotionConfig transition={{ duration: 1, delay: 1.5 }}>
        <motion.div
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          style={{
            width: "90%",
            backgroundColor: "#ffffff30",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            ...CENTER,
            padding: "20px 0",
            borderRadius: 20,
            border: "1px solid #fff",
          }}
        >
          <Stack direction={"column"} spacing={1} width={"90%"}>
            <Stack direction={"column"} spacing={0} width={"90%"}>
              {/* <MotionConfig transition={{ duration: 0.8, delay: 2 }}>
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Typography variant='body1' textAlign={"center"} color='#fff'>
                    Get Together With DRX Token
                  </Typography>
                </motion.span>
              </MotionConfig> */}
              <MotionConfig transition={{ duration: 0.8, delay: 2.3 }}>
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Typography
                    variant='h5'
                    textAlign={"center"}
                    color={Colors.MAIN}
                    fontWeight={"600"}
                  >
                    DRX Token Launch Event
                  </Typography>
                </motion.span>
              </MotionConfig>
            </Stack>
            <div />
            <MotionConfig transition={{ duration: 0.8, delay: 3 }}>
              <motion.p
                key={desc}
                variants={{
                  hidden: {},
                  visible: {
                    opacity: 1,
                    transition: { staggerChildren: 0.1, duration: 0.3 },
                  },
                }}
                initial='hidden'
                animate='visible'
                style={{
                  textAlign: "justify",
                  color: "#C7C7C7",
                  fontSize: "14px",
                }}
              >
                {desc.split("").map((char, i) => (
                  <motion.span
                    key={`${char}-${i}`}
                    variants={{
                      hidden: { opacity: 0 },
                      visible: {
                        opacity: 1,
                        transition: { opacity: { duration: 0.5 } },
                      },
                    }}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.p>
            </MotionConfig>
            <div />
            <MotionConfig transition={{ duration: 0.8, delay: 3.5 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography variant='body1' textAlign={"left"} color='#fff'>
                  Information Event:
                </Typography>
              </motion.span>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 4 }}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <div
                    style={{
                      backgroundColor: Colors.SUBMAIN,
                      borderRadius: 10,
                      ...CENTER,
                      aspectRatio: 1,
                      padding: 10,
                    }}
                  >
                    <Icon fontSize={"medium"} sx={{ color: Colors.MAIN }}>
                      calendar_today_outlined
                    </Icon>
                  </div>
                  <div>
                    <Typography
                      variant='subtitle2'
                      fontWeight={"300"}
                      color='#C7C7C7'
                    >
                      Date:
                    </Typography>
                    <Typography variant='body2' color='#fff'>
                      {moment("2025-03-03").format("dddd, DD MMM YYYY")}
                    </Typography>
                  </div>
                </Stack>
              </motion.div>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 4 }}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <div
                    style={{
                      backgroundColor: Colors.SUBMAIN,
                      borderRadius: 10,
                      ...CENTER,
                      aspectRatio: 1,
                      padding: 10,
                    }}
                  >
                    <Icon fontSize={"medium"} sx={{ color: Colors.MAIN }}>
                      access_time
                    </Icon>
                  </div>
                  <div>
                    <Typography
                      variant='subtitle2'
                      fontWeight={"300"}
                      color='#C7C7C7'
                    >
                      Time:
                    </Typography>
                    <Typography variant='body2' color='#fff'>
                      19:00 / 7:00 PM (GMT+7)
                    </Typography>
                  </div>
                </Stack>
              </motion.div>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 4.5 }}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <div
                    style={{
                      backgroundColor: Colors.SUBMAIN,
                      borderRadius: 10,
                      ...CENTER,
                      aspectRatio: 1,
                      padding: 10,
                    }}
                  >
                    <Icon fontSize={"medium"} sx={{ color: Colors.MAIN }}>
                      place
                    </Icon>
                  </div>
                  <div>
                    <Typography
                      variant='subtitle2'
                      fontWeight={"300"}
                      color='#C7C7C7'
                    >
                      Location:
                    </Typography>
                    <Typography variant='body2' color='#fff'>
                      {"St. Regis Jakarta - Astor Ballroom"}
                    </Typography>
                  </div>
                </Stack>
              </motion.div>
            </MotionConfig>
            <MotionConfig transition={{ duration: 0.8, delay: 5 }}>
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Typography
                  variant='body1'
                  textAlign={"left"}
                  color='#fff'
                  paddingTop={3}
                >
                  Countdown Event:
                </Typography>
              </motion.span>
            </MotionConfig>
            <CountdownEvent />
            {/* {
              searchParams.get("name") ?
                <MotionConfig transition={{ duration: 0.8, delay: 6.5 }}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    style={{ width: "100%", ...CENTER }}
                  >
                    <Stack
                      onClick={onSubmit}
                      spacing={1}
                      direction={"row"}
                      justifyContent={"center"}
                      sx={{
                        border: `1px solid ${Colors.MAIN}`,
                        borderRadius: 50,
                        padding: "12px 16px",
                        backgroundColor: `${Colors.MAIN}`,
                        cursor: "pointer",
                        alignItems: "center",
                        backdropFilter: "blur(20px)",
                        marginTop: "10px",
                        marginBottom: "10px",
                        color: Colors.SUBMAIN,
                        width: '100%'
                      }}
                    >
                      <Typography variant='body1' color={Colors.SUBMAIN}>
                        Submit Attendance
                      </Typography>
                      {
                        isLoading ?
                          <CircularProgress size={20} color={"inherit"} />
                          :
                          <Icon sx={{ color: Colors.SUBMAIN }} fontSize={"small"}>
                            checkcircle
                          </Icon>
                      }
                    </Stack>
                  </motion.div>
                </MotionConfig>
                : null
            } */}
          </Stack>
        </motion.div>
      </MotionConfig>
      <br />
      <MotionConfig transition={{ duration: 0.8, delay: 6.5 }}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{ width: "80%", ...CENTER }}
        >
          <Link
            alignItems={"center"}
            width={"100%"}
            sx={{ textDecoration: "none" }}
            href='https://maps.app.goo.gl/H95ZGnG1DgiFSRAf8'
            target='_blank'
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"center"}
              sx={{
                border: `1px solid ${Colors.MAIN}`,
                borderRadius: 50,
                padding: "12px 16px",
                backgroundColor: "#ffffff30",
                cursor: "pointer",
                alignItems: "center",
                backdropFilter: "blur(20px)",
              }}
            >
              <Typography variant='body1' color={Colors.MAIN}>
                See Location
              </Typography>
              <Icon sx={{ color: Colors.MAIN }} fontSize={"small"}>
                place
              </Icon>
            </Stack>
          </Link>
        </motion.div>
      </MotionConfig>
      <MotionConfig transition={{ duration: 0.8, delay: 6.5 }}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{ width: "80%", ...CENTER }}
        >
          <Link
            alignItems={"center"}
            width={"100%"}
            sx={{ textDecoration: "none" }}
            href='https://linktr.ee/drxcrypto '
            target='_blank'
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"center"}
              sx={{
                border: `1px solid ${Colors.MAIN}`,
                borderRadius: 50,
                padding: "12px 16px",
                backgroundColor: "#ffffff30",
                cursor: "pointer",
                alignItems: "center",
                backdropFilter: "blur(20px)",
              }}
            >
              <Typography variant='body1' color={Colors.MAIN}>
                Information
              </Typography>
              <Icon sx={{ color: Colors.MAIN }} fontSize={"small"}>
                info
              </Icon>
            </Stack>
          </Link>
        </motion.div>
      </MotionConfig>
      <SuccessAlert isOpen={isSuccess} />
      <ErrorAlert isOpen={isError} />
      {/* <MotionConfig transition={{ duration: 0.8, delay: 6.5 }}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{ width: "90%", ...CENTER }}
        >
          <Link
            alignItems={"center"}
            width={"100%"}
            sx={{ textDecoration: "none" }}
            href={
              isSafari || isIOS || isIOS13 || isIPad13 ?
                'https://apps.apple.com/id/app/gudangkripto/id1612764585?platform=iphone'
                :
                'https://play.google.com/store/apps/details?id=id.gudangkripto.mobile&hl=id'
            }
            target='_blank'
          >
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"center"}
              sx={{
                border: `1px solid `,
                borderRadius: 50,
                padding: "12px 16px",
                backgroundColor: "rgba(53, 80, 162, 0.30)",
                backdropFilter: "blur(20px)",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <img src={Assets.icon.logoGK} alt="logo" style={{ width: 40, height: 'auto', objectFit: 'contain' }} />
              <Typography variant='body1' color='#fff'>
                DOWNLOAD GUDANG KRIPTO
              </Typography>
            </Stack>
          </Link>
        </motion.div>
      </MotionConfig> */}
    </Stack>
  );
};
